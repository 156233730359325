/* Estilos generales para imágenes */
img {
  width: 60px;
  height: 60px;
  margin-top: 6px;
}

/* Estilos para el menú de navegación principal */
.navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow: hidden;
}

.navigation-menu li {
  margin: 0 15px; /* Ajuste de espacio entre elementos del menú */
}

.navigation-menu li a {
  text-decoration: none;
  color: rgb(7, 7, 7);
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.navigation-menu li a:hover {
  background-color: #fcfcfc;
}

.active {
  color: #1a1a1a;
  font-weight: 600; /* Ajustado a un valor válido */
}

/* Estilos para el menú de navegación móvil */
.mobile-navigation {
  list-style: none;
  padding: 0;
  display: none; /* Ocultar en pantallas grandes */
  justify-content: start;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.mobile-navigation li {
  margin-top: 20px;
}

.mobile-navigation li a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  padding: 10px;
  display: block;
  transition: background-color 0.3s ease;
}

.mobile-navigation li a:hover {
  background-color: #f0f0f0;
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
  .navigation-menu {
    display: none; /* Ocultar menú principal en pantallas pequeñas */
  }

  .mobile-navigation {
    display: block; /* Mostrar menú móvil en pantallas pequeñas */
  }

  img {
    width: 40px;
    height: 40px;
  }
}

/* Media queries para pantallas muy pequeñas */
@media (max-width: 480px) {
  .navigation-menu li {
    margin: 0 5px;
  }

  .mobile-navigation li {
    margin-top: 15px;
  }

  .mobile-navigation li a {
    font-size: 1rem;
  }

  img {
    width: 30px;
    height: 30px;
  }
}

/* Media queries para pantallas extra pequeñas (máx. 340px) */
@media (max-width: 340px) {
  .mobile-navigation li a {
    font-size: 0.9rem;
    padding: 8px;
  }

  img {
    width: 25px;
    height: 25px;
  }
}

/* Media queries para pantallas ultra pequeñas (máx. 280px) */
@media (max-width: 280px) {
  .mobile-navigation li a {
    font-size: 0.8rem;
    padding: 6px;
  }

  img {
    width: 20px;
    height: 20px;
  }
}
