.footer-container {
  background-color: #ffffff;
  color: #1a1a1a;
  padding: 40px 0; 
  text-align: start;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
  min-height: 200px; /* Or whatever height the footer normally takes */
}

.footer-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

}

.footer-column {
  padding: 20px;
}

.footer-title {
  font-size: 1.125rem;
  margin-bottom: 10px;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.footer-description {
  margin-bottom: 20px;
  font-size: 1rem;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.footer-items-lineal{
  display: inline-block;
}

.footer-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1rem;
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.footer-item p {
  margin-left: 8px;
  font-size: 1rem; 
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;

}

.footer-item a {
  margin-left: 8px;
  font-size: 1rem; 
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;

}



.footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-icon-item {
  color: #1a1a1a;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, color 0.3s ease;
  font-size: 1rem; 
  
}

.footer-icon-item:hover {
  transform: translateY(-5px); 
  color: #fcf75e; 
}

.footer-icon-item p {
  margin-top: 5px;
  font-size: 1rem;
}

.footer-icon-item svg {
  font-size: 4rem; 
}



.footer-links-list {
  list-style: none;
  padding: 0;
}

.footer-link-item {
  margin-bottom: 10px;
}

.footer-link {
  color: #1a1a1a;
  text-decoration: none;
  font-size: 1rem;
}

.footer-link:hover {
  text-decoration: underline;
}


@media (max-width: 992px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-column {
    padding: 10px;
  }

  .footer-title {
    font-size: 1rem;
  }

  .footer-description {
    margin-bottom: 15px;
  }

  .footer-item p {
    margin-left: 8px;
  }
}


@media (max-width: 600px) {
  .footer-grid {
    grid-template-columns: 1fr;
    gap: 15px; 
  }

  .footer-column {
    padding: 10px;
  }

  .footer-title {
    font-size: 0.875rem; 
  }

  .footer-description {
    margin-bottom: 10px;
  }

  .footer-item p {
    margin-left: 5px;
  }


  .footer-icons {
    flex-direction: column;
    gap: 10px;
  }

  .footer-icon-item svg {
    font-size: 1.5rem; 
  }

  .footer-icon-item p {
    font-size: 0.75rem; 
  }



}
